import { stat } from 'fs';
import { emailPattern } from '../appconfig/Settings';

// --------------------------------   START LOGIN PAGE VALIDATION --------------------------  //
export const LoginValidate = (inputDetail: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Input Condition
  if (window.cn(inputDetail.email) && !window.cb(inputDetail.email)) {
    errors = { ...errors, email: 'Enter Email ID *' };
    isError = true;
  } else {
    errors = { ...errors, email: '' };
  }

  if (window.cn(inputDetail.password) && !window.cb(inputDetail.password)) {
    errors = { ...errors, password: 'Enter Password *' };
    isError = true;
  } else {
    errors = { ...errors, password: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

//Four Simple Steps Validate
export const ChangePasswordFormValidate = (inputDetail: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Every Input Condition
  if (window.cn(inputDetail.password) && !window.cb(inputDetail.password)) {
    errors = { ...errors, password: "Enter New Password *" }
    isError = true;
  }
  else {
    errors = { ...errors, password: "" };
  }

  if (window.cn(inputDetail.confirm_password) && !window.cb(inputDetail.confirm_password)) {
    errors = { ...errors, confirm_password: "Enter Confirm Password *" }
    isError = true;
  } else if (inputDetail.password !== inputDetail.confirm_password) {
    errors = { ...errors, confirm_password: "Confirm Password does not Match to Password*" }
    isError = true;
  } else {
    errors = { ...errors, confirm_password: "" };
  }

  //End Every Input Condition

  errorObj = { errors, isError }

  return errorObj;
}

export const jobRoleValidate = (jobRoleName: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Input Condition
  if (window.cn(jobRoleName) && !window.cb(jobRoleName)) {
    errors = { ...errors, name: 'Enter Job Role *' };
    isError = true;
  } else {
    errors = { ...errors, name: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

export const jobPostValidate = (jobPostData: any, jobDescription: any, taskDescription: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Input Condition
  if (window.cn(jobPostData.job_title) && !window.cb(jobPostData.job_title)) {
    errors = { ...errors, job_title: 'Enter Job Title *' };
    isError = true;
  } else {
    errors = { ...errors, job_title: '' };
  }

  if (window.cn(jobDescription) && !window.cb(jobDescription)) {
    errors = { ...errors, job_description: 'Enter Job Description *' };
    isError = true;
  } else {
    errors = { ...errors, job_description: '' };
  }

  if (window.cn(jobPostData.job_role) && !window.cb(jobPostData.job_role)) {
    errors = { ...errors, job_role: 'Select Job Role *' };
    isError = true;
  } else {
    errors = { ...errors, job_role: '' };
  }

  if(localStorage.getItem("user_type") == "admin"){
    if (window.cn(jobPostData.job_company) && !window.cb(jobPostData.job_company)) {
      errors = { ...errors, job_company: 'Select Job Company *' };
      isError = true;
    } else {
      errors = { ...errors, job_company: '' };
    }
  }

  if (window.cn(jobPostData.work_type) && !window.cb(jobPostData.work_type)) {
    errors = { ...errors, work_type: 'Select Work Type *' };
    isError = true;
  } else {
    errors = { ...errors, work_type: '' };
  }

  if (window.cn(jobPostData.job_location) && !window.cb(jobPostData.job_location)) {
    errors = { ...errors, job_location: 'Enter Job Location *' };
    isError = true;
  } else {
    errors = { ...errors, job_location: '' };
  }

  // if (window.cn(jobPostData.salary_from) && !window.cb(jobPostData.salary_from)) {
  //   errors = { ...errors, salary_from: 'Enter Salary From *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, salary_from: '' };
  // }

  // if (window.cn(jobPostData.salary_to) && !window.cb(jobPostData.salary_to)) {
  //   errors = { ...errors, salary_to: 'Enter Salary To *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, salary_to: '' };
  // }

  if (window.cn(jobPostData.experience_from) && !window.cb(jobPostData.experience_from)) {
    errors = { ...errors, experience_from: 'Enter Experience From *' };
    isError = true;
  } else {
    errors = { ...errors, experience_from: '' };
  }

  if (window.cn(jobPostData.experience_from) && !window.cb(jobPostData.experience_from)) {
    errors = { ...errors, experience_to: 'Enter Experience To *' };
    isError = true;
  } else {
    errors = { ...errors, experience_to: '' };
  }

  if (jobPostData.give_task != ""){
    if (window.cn(taskDescription) && !window.cb(taskDescription)) {
      errors = { ...errors, task_description: 'Enter Task Description *' };
      isError = true;
    } else {
      errors = { ...errors, task_description: '' };
    }
  }

  errorObj = { errors, isError };

  return errorObj;
};

export const clientDetailsValidate = (clientDetails: any, editId: any, companyLogo: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Input Condition
  if (window.cn(clientDetails.full_name) && !window.cb(clientDetails.full_name)) {
    errors = { ...errors, full_name: 'Enter Full Name *' };
    isError = true;
  } else {
    errors = { ...errors, full_name: '' };
  }

  if (window.cn(clientDetails.mobile) && !window.cb(clientDetails.mobile)) {
    errors = { ...errors, mobile: 'Enter Mobile Number *' };
    isError = true;
  } else {
    if (clientDetails.mobile.length != 10){
      errors = { ...errors, mobile: 'Please Enter Valid Mobile Number *' };
      isError = true;
    } else{
      errors = { ...errors, mobile: '' };
    }
  }

  if (window.cn(clientDetails.email) && !window.cb(clientDetails.email)) {
    errors = { ...errors, email: 'Enter Email ID *' };
    isError = true;
  } else {
    errors = { ...errors, email: '' };
  }

  if (editId === null){
    if (window.cn(clientDetails.password) && !window.cb(clientDetails.password)) {
      errors = { ...errors, password: 'Enter Password *' };
      isError = true;
    } else {
      errors = { ...errors, password: '' };
    }
  }

  if (window.cn(clientDetails.country) && !window.cb(clientDetails.country)) {
    errors = { ...errors, country: 'Select Country *' };
    isError = true;
  } else {
    errors = { ...errors, country: '' };
  }
  
  if (window.cn(clientDetails.company_name) && !window.cb(clientDetails.company_name)) {
    errors = { ...errors, company_name: 'Enter Company Name *' };
    isError = true;
  } else {
    errors = { ...errors, company_name: '' };
  }

  if (window.cn(companyLogo) && !window.cb(companyLogo)) {
    errors = { ...errors, company_logo: 'Enter Company Logo *' };
    isError = true;
  } else {
    errors = { ...errors, company_logo: '' };
  }

  errorObj = { errors, isError };

  return errorObj;

};

export const brokerDetailsValidate = (clientDetails: any, editId: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Input Condition
  if (window.cn(clientDetails.full_name) && !window.cb(clientDetails.full_name)) {
    errors = { ...errors, full_name: 'Enter Full Name *' };
    isError = true;
  } else {
    errors = { ...errors, full_name: '' };
  }

  if (window.cn(clientDetails.mobile) && !window.cb(clientDetails.mobile)) {
    errors = { ...errors, mobile: 'Enter Mobile Number *' };
    isError = true;
  } else {
    if (clientDetails.mobile.length != 10) {
      errors = { ...errors, mobile: 'Please Enter Valid Mobile Number *' };
      isError = true;
    } else {
      errors = { ...errors, mobile: '' };
    }
  }

  if (window.cn(clientDetails.country) && !window.cb(clientDetails.country)) {
    errors = { ...errors, country: 'Select Country *' };
    isError = true;
  } else {
    errors = { ...errors, country: '' };
  }

  if (window.cn(clientDetails.email) && !window.cb(clientDetails.email)) {
    errors = { ...errors, email: 'Enter Email ID *' };
    isError = true;
  } else {
    errors = { ...errors, email: '' };
  }

  if (editId === null) {
    if (window.cn(clientDetails.password) && !window.cb(clientDetails.password)) {
      errors = { ...errors, password: 'Enter Password *' };
      isError = true;
    } else {
      errors = { ...errors, password: '' };
    }
  }

  errorObj = { errors, isError };

  return errorObj;

};


export const investorsDetailsValidate = (clientDetails: any, editId: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Input Condition
  if (window.cn(clientDetails.full_name) && !window.cb(clientDetails.full_name)) {
    errors = { ...errors, full_name: 'Enter Full Name *' };
    isError = true;
  } else {
    errors = { ...errors, full_name: '' };
  }

  if (window.cn(clientDetails.mobile) && !window.cb(clientDetails.mobile)) {
    errors = { ...errors, mobile: 'Enter Mobile Number *' };
    isError = true;
  } else {
    if (clientDetails.mobile.length != 10) {
      errors = { ...errors, mobile: 'Please Enter Valid Mobile Number *' };
      isError = true;
    } else {
      errors = { ...errors, mobile: '' };
    }
  }

  if (window.cn(clientDetails.email) && !window.cb(clientDetails.email)) {
    errors = { ...errors, email: 'Enter Email ID *' };
    isError = true;
  } else {
    errors = { ...errors, email: '' };
  }

  if (window.cn(clientDetails.country) && !window.cb(clientDetails.country)) {
    errors = { ...errors, country: 'Select Country *' };
    isError = true;
  } else {
    errors = { ...errors, country: '' };
  }

  if (editId === null) {
    if (window.cn(clientDetails.password) && !window.cb(clientDetails.password)) {
      errors = { ...errors, password: 'Enter Password *' };
      isError = true;
    } else {
      errors = { ...errors, password: '' };
    }
  }

  if (window.cn(clientDetails.login_revoke) && !window.cb(clientDetails.login_revoke)) {
    errors = { ...errors, login_revoke: 'Enter Login Access Days *' };
    isError = true;
  } else {
    errors = { ...errors, login_revoke: '' };
  }

  errorObj = { errors, isError };

  return errorObj;

};


export const investmentMaterialValidate = (materialUrl: any, manualFile: any, fileName: any, selectedFolder: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Input Condition
  if (materialUrl == ""){
    if (window.cn(manualFile) && !window.cb(manualFile)) {
      errors = { ...errors, manual_file: 'Upload File *' };
      isError = true;
    } else {
      errors = { ...errors, manual_file: '' };
    }
  }

  if (window.cn(fileName) && !window.cb(fileName)) {
    errors = { ...errors, file_name: 'Enter File Name *' };
    isError = true;
  } else {
    errors = { ...errors, file_name: '' };
  }

  if (Object.keys(selectedFolder).length == 0) {
    errors = { ...errors, folder: 'Select Folder *' };
    isError = true;
  } else {
    errors = { ...errors, folder: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

export const investmentMaterialFolderNameValidate = (folderName: any) => {
  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Input Condition
  if (window.cn(folderName) && !window.cb(folderName)) {
    errors = { ...errors, folder_name: 'Enter Folder Name *' };
    isError = true;
  } else {
    errors = { ...errors, folder_name: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

