import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import parse from 'html-react-parser';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';

//Services
import PageService from '../../../service/PageService';

import { Loader } from '../../../components/Loader/Loader';

export const CareerJobDetailsView = () => {
    document.title = "Job Details | Venture Studio"

    //Navigate Another Route
    const navigate = useNavigate();
    const location = useLocation();

    const pageService = new PageService();

    const [pageLoad, setPageLoad] = useState<boolean>(false);
    const [careerId, setCareerId] = useState<any>({});
    const [careerData, setCareerData] = useState<any>({});

    useEffect(() => {
        if (location.state) {
            const state = location.state;
            setCareerId(state);
            getSingleCareerDataFromAPI(state);
        }
    }, []);

    // Get single career data
    const getSingleCareerDataFromAPI = (state: any) => {
        setPageLoad(true);
        // Api call
        pageService
            .getSingleCareerJob(state.career_id)
            .then((response) => {
                // Get response
                if (response) {
                    setCareerData(response);
                    setPageLoad(false);
                } else {
                    setCareerData({});
                    setPageLoad(false);
                }
            });
    };

    return (
        <>
            <h3> <Button
                icon="pi pi-arrow-left"
                className="p-button-secondary mr-2"
                onClick={() => navigate(-1)}
            />    Job Details</h3><br />
            {/* <h5 style={{ marginLeft: "16px" }}> <b>Status</b> : {!window.cn(careerData) && careerData["status"] == "Published" ? <><Badge value="Published" severity="success"></Badge></> : <><Badge value="Inactive" severity="warning"></Badge></>}</h5><br /> */}

            <div className='card'>
                <div className="field col">
                    <div className="grid">
                        <div className="field col-4 flex flex-column">
                            <p> <b>Job Title</b> : {!window.cn(careerData) ? careerData?.job_title : ""}</p>
                        </div>

                        <div className="field col-4 flex flex-column">
                            <p> <b>Company</b> : {!window.cn(careerData) ? careerData?.company?.company_name : ""}</p>
                        </div>

                        <div className="field col-4 flex flex-column">
                            <p> <b>Role</b> : {!window.cn(careerData) ? careerData?.job_role?.name : ""}</p>
                        </div>

                        <div className="field col-12 flex flex-column">
                            <p> <b>Description</b> : {!window.cn(careerData) && careerData?.job_description != undefined ? parse(careerData?.job_description) : ""}</p>
                        </div>

                        <div className="field col-4 flex flex-column">
                            <p> <b>Location</b> : {!window.cn(careerData) ? careerData?.job_location : ""}</p>
                        </div>

                        {!window.cn(careerData) && !window.cn(careerData?.salary_from) ?
                            <div className="field col-4 flex flex-column">
                                <p> <b>Salary</b> : {!window.cn(careerData) ? careerData?.salary_from + " - " + careerData?.salary_to : ""}</p>
                            </div>
                            : 
                            <></>
                        }

                        <div className="field col-4 flex flex-column">
                            <p> <b>Experience</b> : {!window.cn(careerData) ? careerData?.experience_from + " - " + careerData?.experience_to : ""}</p>
                        </div>

                        <div className="field col-4 flex flex-column">
                            <p> <b>Work Type</b> : {!window.cn(careerData) ? careerData?.work_type : ""}</p>
                        </div>

                        {
                            !window.cn(careerData) && careerData?.give_task == "Yes" ? 
                            <>
                                    <div className="field col-12 flex flex-column">
                                        <p> <b>Task Description</b> : {!window.cn(careerData) && careerData?.task_description != undefined ? parse(careerData?.task_description) : ""}</p>
                                    </div>
                            </>
                            :
                            <></>
                        }
                    </div>
                </div>
            </div>

            {
                pageLoad && <Loader />
            }
        </>
    )
}