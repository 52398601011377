
//Empty Temlate for image upload
export const emptyTemplate = () => {
    return (
        <div className="flex align-items-center flex-column">
            <i className="pi pi-image p-5" style={{ fontSize: '1.5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
            <span style={{ fontSize: '1em', color: 'var(--text-color-secondary)' }} className="my-3">
                Drag and Drop Image Here
            </span>
        </div>
    );
  };

  //Header for IMAGE UPLOAD
  export const headerTemplate = (options: any) => {
    const { className, chooseButton } = options;

    return (
        <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
            {chooseButton}
        </div>
    );
};

  
  //Image upload icon for image upload 
  export const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };

  //convert blob url to file object
  export const convertBlobToFile = (file : any) => {
      var files:any = new File([file.objectURL], `${file.name}` ,{ type: file.type } );
      return files;
    // var file:any = await fetch(file).then(r => r.blob()).then(blobFile => 
    //     (
    //         console.log("file",blobFile)
    //     )
    //     // new File([blobFile], fileName, { type: blobFile.type })
    //     )
  }