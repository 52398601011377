import { APP_BASE_URL, convertDateFrom } from '../appconfig/Settings';
import {
  ADD_INVESTMENT_MATERIAL,
  ADD_READ_TIME_OF_INVT_MATERIAL,
  ADD_UPDATE_CAREER_JOBS,
  ADD_UPDATE_CLIENT,
  ADD_UPDATE_INVT_MATERIAL_FOLDER,
  ADD_UPDATE_JOB_ROLE,
  APPLICANT_STATUS_CHANGE,
  APPROVE_INVESTMENT_MATERIAL_ACCESS,
  APPROVE_REJECT_CLIENT,
  CHANGE_CAREER_JOB_POST_STATUS,
  CHANGE_JOB_ROLE_STATUS,
  CHANGE_PASSWORD,
  CLEAR_NOTIFICATION,
  DELETE_CLIENT,
  DELETE_FOLDER,
  DELETE_INVESTMENT_MATERIAL,
  GET_ALL_INVESTMENT_APPROVAL_MATERIAL,
  GET_ALL_INVESTMENT_MATERIAL,
  GET_ALL_INVT_MATERIAL_FOLDER,
  GET_APPLICANTS_LIST,
  GET_BROKER_INVT_LIST_DROPDOWN,
  GET_CAREER_JOBS,
  GET_CLIENTS_LIST,
  GET_COUNTRIES,
  GET_DASHBOARD_DATA,
  GET_FILE_OR_FOLDER_DETAILS,
  GET_INVESTOR_MATERIAL_DATA,
  GET_JOB_ROLES,
  GET_NOTIFICATIONS,
  GET_SINGLE_APPLICANT_DATA,
  GET_SINGLE_CAREER_JOB,
  GET_SINGLE_CLIENT_DATA,
  GET_SINGLE_INVESTMENT_MATERIAL,
  GET_SINGLE_JOB_ROLE,
  GET_USER_ALL_NOTIFICATIONS,
  INVESTMENT_MATERIAL_ACCESS,
  SET_BROCHURE_DATA,
  UPLOAD_BROCHURE,
  VIEW_INVESTMENT_MATERIAL,
} from '../config/ApiConstant';
import axiosInstance from '../config/axiosInstance';

export default class CommonService {

  // Get countries
  getCountries() {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_COUNTRIES
      )
      .then((res) => res.data.data);
  }

  // Get dashboard data
  getDashboardData() {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_DASHBOARD_DATA
      )
      .then((res) => res.data.data);
  }

  // Get notifications
  getNotifications() {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_NOTIFICATIONS
      )
      .then((res) => res.data.data);
  }

  // Clear notification
  clearNotification(id: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        CLEAR_NOTIFICATION + 
        "?id=" + id
      )
      .then((res) => res.data);
  }

  // Get user all notifications
  getUserAllNotifications(id: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_USER_ALL_NOTIFICATIONS +
        "?id=" + id
      )
      .then((res) => res.data.data);
  }

  //Change Password
  changePassword(formRequestData: any) {
    return axiosInstance.post(APP_BASE_URL + CHANGE_PASSWORD, formRequestData).then((res) => res.data);
  }

  // Add update client
  addUpdateClientApiCall(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + ADD_UPDATE_CLIENT, requestData)
      .then((res) => res.data);
  }

  // Get Clients List
  getClientsList(status: any, user_type: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_CLIENTS_LIST + 
        "?status=" + status + 
        "&user_type=" + user_type
      )
      .then((res) => res.data.data);
  }

  // Get Single Client Details
  getSingleClientDetails(id: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_SINGLE_CLIENT_DATA +
        "?id=" + id
      )
      .then((res) => res.data.data);
  }

  // Delete Client
  deleteClient(id: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        DELETE_CLIENT +
        "?id=" + id
      )
      .then((res) => res.data);
  }

  //Approve Reject Client
  approveRejectClient(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + APPROVE_REJECT_CLIENT, requestData)
      .then((res) => res.data);
  }

  // Upload Client Brochure
  uploadClientBrochure(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + UPLOAD_BROCHURE, requestData)
      .then((res) => res);
  }

  // Open Client Brochure
  setClientBrochureData(client_id: any) {
    return axiosInstance
      .get(APP_BASE_URL + SET_BROCHURE_DATA + "?client_id=" + client_id)
      .then((res) => res);
  }

  // Add update career jobs
  addUpdateCareerJobs(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + ADD_UPDATE_CAREER_JOBS, requestData)
      .then((res) => res);
  }

  // Get career jobs
  getCareerJobs(status: any, job_company: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_CAREER_JOBS +
        "?status=" + status +
        "&job_company=" + job_company
      )
      .then((res) => res.data.data);
  }

  // Get Single Client Details
  getSingleCareerJob(id: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_SINGLE_CAREER_JOB +
        "?id=" + id
      )
      .then((res) => res.data.data);
  }

  // Change career job post status
  changeCareerJobPostStatus(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + CHANGE_CAREER_JOB_POST_STATUS, requestData)
      .then((res) => res.data);
  }

  // Add update job roles
  addUpdateJobRole(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + ADD_UPDATE_JOB_ROLE, requestData)
      .then((res) => res);
  }

  // Get job roles
  getJobRolesList(status: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_JOB_ROLES +
        "?status=" + status
      )
      .then((res) => res.data.data);
  }

  // Get single job role
  getSingleJobRole(job_role_id: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_SINGLE_JOB_ROLE +
        "?id=" + job_role_id
      )
      .then((res) => res.data.data);
  }

  // Change job role status
  changeJobRoleStatus(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + CHANGE_JOB_ROLE_STATUS, requestData)
      .then((res) => res.data);
  }

  // Get job roles for dropdown
  getJobRolesListForDropdown() {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_JOB_ROLES +
        "?type=active"
      )
      .then((res) => res.data.data);
  }

  // Get job companies for dropdown
  getJobCompaniesListForDropdown() {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_CLIENTS_LIST +
        "?type=active"
      )
      .then((res) => res.data.data);
  }

  // Get applicants list
  getApplicantsList(status: any, company_id: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_APPLICANTS_LIST +
        "?status=" + status +
        "&company_id=" + company_id
      )
      .then((res) => res.data.data);
  }

  // Get single applicant details
  getSingleApplicantDetails(applicant_id: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_SINGLE_APPLICANT_DATA +
        "?id=" + applicant_id
      )
      .then((res) => res.data.data);
  }

  // Applicant status change
  applicantStatusChange(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + APPLICANT_STATUS_CHANGE, requestData)
      .then((res) => res.data);
  } 

  // Get all investment material list
  getAllInvestmentMaterial(type:any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_ALL_INVESTMENT_MATERIAL + 
        "?type=" + type
      )
      .then((res) => res.data);
  }

  // Get Single investment material
  getSingleInvestmentMaterial(id: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_SINGLE_INVESTMENT_MATERIAL +
        "?id=" + id
      )
      .then((res) => res.data.data);
  }

  // Get file or folder details
  getFileOrFolderDetails(url: any, id: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_FILE_OR_FOLDER_DETAILS +
        "?url=" + url + 
        "&id=" + id
      )
      .then((res) => res.data.data);
  }

  // Add investment material
  addInvestmentMaterial(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + ADD_INVESTMENT_MATERIAL, requestData)
      .then((res) => res);
  }

  // Delete investment material
  deleteInvestmentMaterial(id: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        DELETE_INVESTMENT_MATERIAL +
        "?id=" + id
      )
      .then((res) => res.data);
  }

  // Investment material access
  investmentMaterialAccess(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + INVESTMENT_MATERIAL_ACCESS, requestData)
      .then((res) => res.data);
  }

  // Get file or folder details
  getInvestorMaterialData() {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_INVESTOR_MATERIAL_DATA
      )
      .then((res) => res.data.data);
  }

  // View investment material
  viewInvestmentMaterial(id: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        VIEW_INVESTMENT_MATERIAL +
        "?file_id=" + id
      )
      .then((res) => res.data.data);
  }

  // Get file or folder details
  getAllInvestmentApprovalMaterial(status: any, broker_id: any, investor_id: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_ALL_INVESTMENT_APPROVAL_MATERIAL + 
        "?status=" + status +
        "&broker_id=" + broker_id +
        "&investor_id=" + investor_id
      )
      .then((res) => res.data.data);
  }

  // Add investment material
  approveInvestmentMaterialAccess(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + APPROVE_INVESTMENT_MATERIAL_ACCESS, requestData)
      .then((res) => res.data);
  }

  // Get brokers and investors list for dropdown
  getBrokerInvestorListDropdown() {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_BROKER_INVT_LIST_DROPDOWN
      )
      .then((res) => res.data);
  }

  // Add read time of investment material
  addReadTimeOfInvtMaterial(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + ADD_READ_TIME_OF_INVT_MATERIAL, requestData)
      .then((res) => res.data);
  }

  // Add update investmnet material folder
  addUpdateInvtMaterialFolder(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + ADD_UPDATE_INVT_MATERIAL_FOLDER, requestData)
      .then((res) => res.data);
  }

  // Get brokers and investors list for dropdown
  getAllInvtMaterialFolder() {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_ALL_INVT_MATERIAL_FOLDER
      )
      .then((res) => res.data.data);
  }

  // Delete Folder
  deleteFolder(id: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        DELETE_FOLDER +
        "?id=" + id
      )
      .then((res) => res.data);
  }
}
