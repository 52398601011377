import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import parse from 'html-react-parser';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';

//Services
import PageService from '../../../service/PageService';

import { Loader } from '../../../components/Loader/Loader';

export const CareerApplicantsDetail = () => {
    document.title = "Applicant Detail | Venture Studio"

    //Navigate Another Route
    const navigate = useNavigate();
    const location = useLocation();

    const pageService = new PageService();

    const [pageLoad, setPageLoad] = useState<boolean>(false);
    const [applicantId, setApplicantId] = useState<any>({});
    const [applicantData, setApplicantData] = useState<any>({});
    const options = ['Off', 'On'];
    const [value, setValue] = useState<any>(options[0]);

    useEffect(() => {
        if (location.state) {
            const state = location.state;
            setApplicantId(state);
            getSingleApplicantDataFromAPI(state);
        }
    }, []);

    // Get single applicant data
    const getSingleApplicantDataFromAPI = (state: any) => {
        setPageLoad(true);
        // Api call
        pageService
            .getSingleApplicantDetails(state.applicant_id)
            .then((response) => {
                // Get response
                if (response) {
                    setApplicantData(response);
                    setPageLoad(false);
                } else {
                    setApplicantData({});
                    setPageLoad(false);
                }
            });
    };

    return (
        <>
            <div className='main-header'>
                <h3> <Button
                    icon="pi pi-arrow-left"
                    className="p-button-secondary mr-2"
                    onClick={() => navigate(-1)}
                />    Applicant Details</h3><br />
                <div className='mb-3'>
                    <Button
                        label='Resume'
                        icon="pi pi-file-pdf"
                        className="p-button-danger mr-2"
                        onClick={() => window.open(applicantData?.resume, "_blank")}
                    />
                    {
                        !window.cn(applicantData) && applicantData?.job_post?.give_task == "Yes" ?
                            <>
                                <Button
                                    label='Task File'
                                    icon="pi pi-file"
                                    className="p-button-info mr-2"
                                    onClick={() => window.open(applicantData?.task_file, "_blank")}
                                />
                            </>
                        :
                            <></>
                    }
                </div>
                
            </div>

            <div className='card'>
                <div className="field col">
                    <div className="grid">
                        <div className="field col-4 flex flex-column">
                            <p> <b>Name</b> : {!window.cn(applicantData) ? applicantData?.first_name + " " + applicantData?.last_name : ""}</p>
                        </div>

                        <div className="field col-4 flex flex-column">
                            <p> <b>Email</b> : {!window.cn(applicantData) ? applicantData?.email : ""}</p>
                        </div>

                        <div className="field col-4 flex flex-column">
                            <p> <b>Mobile No.</b> : {!window.cn(applicantData) ? applicantData?.mobile : ""}</p>
                        </div>
                    </div>
                    <div className="flex justify-content-center">
                        
                    </div>
                </div>
            </div>

            <div className='card'>
                <div className="field col">
                    <h4><b>Applied Job Details</b></h4><br/>
                    <div className="grid">
                        <div className="field col-3 flex flex-column">
                            <p> <b>Job Title</b> : {!window.cn(applicantData) ? applicantData?.job_post?.job_title : ""}</p>
                        </div>

                        <div className="field col-3 flex flex-column">
                            <p> <b>Company</b> : {!window.cn(applicantData) ? applicantData?.company?.company_name : ""}</p>
                        </div>

                        <div className="field col-3 flex flex-column">
                            <p> <b>Role</b> : {!window.cn(applicantData) ? applicantData?.job_post?.job_role?.name : ""}</p>
                        </div>

                        <div className="field col-3 flex flex-column">
                            <p> <b>Location</b> : {!window.cn(applicantData) ? applicantData?.job_post?.job_location : ""}</p>
                        </div>

                        {!window.cn(applicantData) && !window.cn(applicantData?.job_post?.salary_from) ?
                            <div className="field col-3 flex flex-column">
                                <p> <b>Salary</b> : {!window.cn(applicantData) ? applicantData?.job_post?.salary_from + " - " + applicantData?.job_post?.salary_to : ""}</p>
                            </div>
                            :
                            <></>
                        }

                        <div className="field col-3 flex flex-column">
                            <p> <b>Experience</b> : {!window.cn(applicantData) ? applicantData?.job_post?.experience_from + " - " + applicantData?.job_post?.experience_to + " Years" : ""}</p>
                        </div>

                        <div className="field col-3 flex flex-column">
                            <p> <b>Work Type</b> : {!window.cn(applicantData) ? applicantData?.job_post?.work_type : ""}</p>
                        </div>

                        <div className="field col-12 flex flex-column">
                            <p> <b>Description</b> : {!window.cn(applicantData) && applicantData?.job_post?.job_description != undefined ? parse(applicantData?.job_post?.job_description) : ""}</p>
                        </div>

                        {
                            !window.cn(applicantData) && applicantData?.job_post?.give_task == "Yes" ?
                                <>
                                    <div className="field col-12 flex flex-column">
                                        <p> <b>Task Description</b> : {!window.cn(applicantData) && applicantData?.job_post?.task_description != undefined ? parse(applicantData?.job_post?.task_description) : ""}</p>
                                    </div>
                                </>
                                :
                                <></>
                        }
                    </div>
                </div>
            </div>

            {
                pageLoad && <Loader />
            }
        </>
    )
}